.Dashboard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .subDashboard {
    display: flex;
    margin: 0 0 0 -1em;
    > div {
      padding-left: 1em;
      padding-bottom: 2em;
    }
  }

  .subDashboard + .subDashboard {
    margin-bottom: 0;
  }
}
