.Document {
  .Document-header {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 0.3em;
  }

  .FilesContainer {
    display: flex;
    flex-wrap: wrap;

    .File {
      margin: 0 5px 5px;
      height: 100px;
      width: 90px;
      background-color: var(--main-blue);
      color: white;
      display: flex;
      font-size: x-small;
      align-items: center;
      border-radius: 5px;
      position: relative;
      padding: 5px;
      flex-wrap: wrap;
      span {
        overflow: hidden;
        width: 100%;
      }

      .StatusIndicator {
        position: absolute;
        top: -5px;
        right: -5px;
        border-radius: 50%;
        background-color: var(--main-yellow);
        width: 22px;
        height: 22px;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
