.UnknownPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  a {
    font-size: large;
    color: var(--main-blue);
  }
}
