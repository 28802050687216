.Timeline {
  border: 1px solid var(--main-green);
  padding: 24px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
  transition: all 200ms ease;

  a {
    transition: all 200ms ease;
  }
  .projectName {
    font-weight: bold;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .see-project {
    z-index: 10;
    background-color: var(--main-green);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    min-height: 50px;
    min-width: 50px;
    top: calc(10% - 25px);
    right: -25px;
    box-shadow: 1px 1px 6px #aaa;
    transition: all 200ms ease;
    //&:hover {
    //  background-color: white;
    //  color: var(--main-green);
    //}
  }

  //&:hover {
  //  background-color: var(--main-green);
  //  color: white;
  //  a {
  //    color: white;
  //  }
  //  .see-project {
  //    background-color: white;
  //    color: var(--main-green);
  //  }
  //}

  .stepper-title-unstarted,
  .stepper-title-completed {
    font-size: 14px !important;
  }

  //&:hover .stepper-title-unstarted {
  //  color: white !important;
  //}
  //
  //&:hover .stepper-title-completed {
  //  color: white !important;
  //  font-weight: 600 !important;
  //}

  svg {
    min-width: 12px;
    width: 15px;
    margin-left: 3px;
    transform: rotate(0.25turn);
  }
}

@media (max-width: 576px) {
  .Timeline {
    overflow: auto;
  }
}
