.UserNotification {
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 0.5em 0.5em 0.75em;

  cursor: pointer;

  transition: all 200ms ease;
  user-select: none;

  &::before {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    background-color: var(--main-green);
    opacity: 0;
    transition: all 200ms ease;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  .UserNotificationContent {
    flex: auto;
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    width: 100%;

    .UserNotificationIcon {
      margin: 0 0.5em 0;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .description {
      text-align: center;
      width: 100%;
    }
  }

  .date {
    align-self: flex-end;
    justify-self: flex-end;
    transition: all 200ms ease;
    font-size: small;
    color: darkgrey;
  }

  border-bottom: solid 1px lightgrey;
}
