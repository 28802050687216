a {
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
    text-decoration: none;
  }
}

.card-header {
  font-size: 18px;
}

.card {
  border-radius: 0;
  border: none;
}

.btn {
  border-radius: 0;
}

.btn-fa {
  background-color: var(--main-green);
  color: white;
  &:hover {
    color: white;
    background-color: darken(#00b195, 10%);
  }
}

.btn-naked {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.popover {
  font-family: var(--font-family-sans);
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
}

.w-33 {
  width: 33% !important;
}
.w-66 {
  width: 66% !important;
}

.tooltip-inner {
  background-color: white;
  border: 2px solid #c0ccda;
  border-radius: 10px;
  width: 177px;
  height: 70px;
  color: #8190a5;
  //font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding-top: 12px;
}

.tooltip.show {
  opacity: 1;
}
