.ResourceLink {
  height: 100%;
  border: 1px solid #c6c6c6;
  cursor: pointer;

  &-Image {
    height: 140px;
    object-fit: cover;
    &.card-img-top {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    &.doc {
      object-fit: contain !important;
    }
  }
  &-Title {
    font-family: 'Zilla Slab', serif;
    font-weight: bold;
  }
  &-Subtitle {
    font-style: italic;
  }

  &-Description {
    font-size: 12px;
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative;
    /* use this value to count block height */
    line-height: 1.2em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 3.6em;
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;
    /* place for '...' */
    margin-right: -1em;
    padding-right: 1em;
  }

  &-Description:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }

  &-Description:after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: white;
  }
}
