.ContactCard {
  border-radius: 5px;
  border: 1px solid var(--main-green);
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  height: 100%;
  align-items: center;

  &-ContactInfo {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    flex: auto;
  }

  &-ContactType {
    font-size: small;
  }

  &-ContactName {
    font-weight: bold;
  }
}
