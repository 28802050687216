.ChangePassword {
  .inp {
    .PasswordStrength {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      // strength 0-2 are always visible
      &.is-strength-0 .PasswordStrength-strength-bar {
        background: #d1462f;
        width: 20%;
      }

      &.is-strength-1 .PasswordStrength-strength-bar {
        background: #d1462f;
        width: 40%;
      }

      &.is-strength-2 .PasswordStrength-strength-bar {
        background: var(--main-yellow);
        width: 60%;
      }
    }

    .PasswordStrength-strength-bar {
      box-sizing: border-box;
      bottom: 0;
      left: 0;
      height: 2px;
      transition: width 300ms ease-out;
      width: 0;
    }

    input {
      &:focus {
        + span {
          // label
          + .PasswordStrength {
            &.is-strength-3 .PasswordStrength-strength-bar {
              background: var(--main-blue);
              width: 80%;
            }

            &.is-strength-4 .PasswordStrength-strength-bar {
              background: var(--main-green);
              width: calc(100% + 2px);
            }
          }
        }
      }
    }
  }

  .success-msg {
    color: var(--main-green);
  }

  .error-msg {
    color: var(--main-red);
  }
}
