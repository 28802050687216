.Footer {
  display: flex;
  background-color: var(--main-green);
  padding: 20px 10px;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 12px;
  margin-right: 0 !important;

  &-social-networks {
    margin-left: 40px;
    a {
      margin-left: 28px;
    }
  }

  &-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
    text-align: center;
    a {
      color: white !important;
      margin: 0 6px;
      &:first-child {
        margin: 0 6px 0 0;
      }
    }
  }

  &-partners {
    display: flex;
    align-items: center;
    background-color: inherit;
    justify-content: flex-end;
    flex-flow: row wrap;
    a {
      margin-left: 20px;
    }
  }
}
