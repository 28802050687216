.InfiniteRessources {
  display: flex;
  flex-flow: row wrap;

  .loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Resource {
    margin-bottom: 10px;
  }
}
