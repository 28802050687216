.AvancementProjet {
  .card {
    min-height: 100%;
    .card-body {
      display: flex;
    }
  }
  .Timelines {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
  }
}
