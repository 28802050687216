.inp {
  position: relative;
  width: 100%;
  margin: auto auto 1em;

  .label {
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 16px;
    color: #9098a9;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all 0.2s ease;
    user-select: none;
  }

  &:not(.no-border) {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background: var(--main-green);
      transform: scaleX(0);
      transform-origin: 0 0;
      transition: all 0.2s ease;
    }
  }

  input {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 12px 0;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #c8ccd4;
    background: none;
    border-radius: 0;
    color: #444;
    transition: all 0.2s ease;
    position: relative;

    &:hover {
      background: rgba(34, 50, 84, 0.03);
    }

    &:not(:placeholder-shown) + .label {
      color: #5a667f;
      transform: translateY(-26px) scale(0.75);
    }

    &:focus {
      background: none;
      outline: none;
    }
  }

  &:focus-within {
    .label {
      transform: translateY(-26px) scale(0.75);
      color: var(--main-green) !important;
      font-size: larger;
    }

    &::after {
      transform: scaleX(1);
    }
  }

  &.has-error {
    .label {
      color: darkred !important;
    }

    &:focus-within {
      &::after {
        background: darkred;
      }
    }
  }
}
.inp-errors {
  color: darkred !important;
  font-size: smaller;
  font-style: italic;
  margin-bottom: 10px;
  margin-top: -10px;
}
