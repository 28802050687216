:root {
  --main-white: #fff;
  --main-green: #00b195;
  --main-green-1: #00ccaa;
  --main-green-2: #b2e7df;
  --main-green-3: #5ee1cb;
  --main-yellow: #daa920;
  --main-yellow-2: #ffba5c;
  --main-yellow-3: #f3e5bc;
  --main-blue: #26aae1;
  --main-blue-lighter: #9bcdff;
  --main-blue-2: #f4f7fc;
  --main-blue-3: #e9f1fd;
  --main-red: #ee7374;
  --main-red-2: #f8d5d5;
  --main-light-gray: #f2f2f2;
  --main-gray: #777777;
  --main-gray-2: #cbcbcb;
  --main-gray-3: #f1f1f1;
  --main-gray-4: #5a6978;
  --main-black-1: #000000;
  --main-black-2: #353f4b;
  --main-violet: #6f60aa;
  --main-violet-2: #e2dfee;
}
