.Operations {
  .title {
    color: #5a6978;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    width: 250px;
    text-align: left;
  }

  .show-operations {
    color: #5a6978;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    text-align: center;
    padding-top: 15px !important;
    display: block;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .show-products-link {
    transform: rotate(90deg);

    &.toggled {
      transform: rotate(-90deg);
    }
  }

  .operation-progress {
    position: relative;
    height: 10px;
    background-color: var(--main-light-gray);
    width: 100%;

    &::before {
      height: 10px;
      content: '';
      position: absolute;
      top: 0;
      transition: width 100ms linear;
      left: 0;
    }
  }

  .timeline-table {
    display: flex;
    color: #5a6978;
    line-height: 20px;
    width: 100%;
    align-content: center;
    padding-top: 5px;
    padding-bottom: 15px;

    .step {
      flex: 1 1 0;
      margin-right: 2px;

      .title {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
      }

      .fill {
        background-color: #5ecbab;
        width: 100%;
        height: 10px;
      }
    }

    #committeeIconSpan {
      margin-left: -7px;
      margin-top: -4px;

      .committeeIcon {
        font-size: 18px;
        transform: none;
      }
    }
  }
}
