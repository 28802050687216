.Tabs {
  display: flex;

  .Tab {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: solid 2px var(--main-green);
    padding: 10px 30px;
    color: black;
    cursor: pointer;
    user-select: none;

    transition: all ease 200ms;
    font-weight: bold;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.Tab-active {
      color: white;
      background-color: var(--main-green);
    }
    &.Tab-disabled {
      cursor: not-allowed;
      color: darkgrey;
    }

    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
}

@media (max-width: 992px) {
  .Tabs {
    flex-direction: column;
    position: relative;
    &::before {
      content: '';
      width: 5px;
      background-color: var(--main-green);
      height: 100%;
      top: 0;
      position: absolute;
    }

    .Tab {
      border-bottom: none;
      align-items: flex-start;
      text-align: left;

      &:first-child {
        padding-left: 30px;
      }
    }
  }
}
