.timeline-table {
  display: flex;
  color: #5a6978;
  line-height: 20px;
  width: 100%;
  align-content: center;
  padding-top: 15px;
  padding-bottom: 15px;

  .step {
    flex: 1 1 0;
    margin-right: 2px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .title {
      margin-top: auto;
      padding-bottom: 5px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      word-break: break-word;
    }

    .fill {
      background-color: #5ecbab;
      width: 100%;
      height: 20px;
    }
  }
}
