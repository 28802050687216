.Notifications {
  &-content {
    padding: 1em 2em 0 2em;
    &-switch {
      margin-top: 0.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  > div {
    margin-bottom: 2em;
  }
}
