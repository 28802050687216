.Messenger {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &-ProjectSwitch {
    cursor: pointer;
    margin-bottom: 10px;
    transition: all 100ms, color 100ms;
    border-radius: 5px;
    border: solid 1px var(--main-green);
    font-weight: bold;

    &:hover {
      background-color: lighten(#00b195, 5%);
      color: white;
    }

    .Messenger-NewMessageCounter {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--main-green);
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      color: white;
    }

    &-Selected {
      background-color: var(--main-green);
      color: white;

      .Messenger-NewMessageCounter {
        background-color: var(--main-red);
      }
    }
  }

  .MessengerSection {
    height: 400px;
  }

  .AdvisorContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &-name {
      color: var(--main-green);
      font-weight: bold;
    }

    &-contact {
      font-weight: bold;
    }

    &-info {
      color: #555;
    }
  }
}

@media (max-width: 576px) {
  .Messenger {
    .MessengerSection {
      min-height: 400px;
    }
  }
}
