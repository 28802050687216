@mixin flex-wrap-fix($flex-basis, $max-viewport-width: 2000px) {
  flex-grow: 1;
  flex-basis: $flex-basis;
  max-width: 100%;

  $multiplier: 1;
  $current-width: 0px;

  @while $current-width < $max-viewport-width {
    $current-width: $current-width + $flex-basis;
    $multiplier: $multiplier + 1;

    @media (min-width: $flex-basis * $multiplier) {
      max-width: percentage(1 / $multiplier);
    }
  }
}

.ProjectMessenger {
  height: 80vh;
  display: flex;
  flex-direction: column;

  .Members {
    flex-grow: 0;

    .ContactCards {
      display: flex;
      flex-flow: row wrap;
      flex: auto;
      margin-left: -1em;
      max-height: 300px;
      overflow-y: auto;

      > div {
        @include flex-wrap-fix(300px);
        margin-bottom: 0.5em;
        min-width: 215px;

        //max-width: 215px;
        height: 90px;
        max-height: 100px;
        margin-left: 1em;
      }
    }
  }

  &-Messages-Container {
    overflow-y: auto;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    > div {
      flex: 0 0 auto;
      .UnreadDivider {
        position: sticky;
        top: -5px;
        display: flex;
        background: rgba(255, 255, 255, 0.8);
        color: red;
        align-items: center;
        justify-content: center;
        &::before,
        &::after {
          content: '';
          height: 1px;
          flex: 1;
          background-color: red;
          margin: 1em;
        }
      }
      .DayDivider {
        position: sticky;
        top: -5px;
        display: flex;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.8);
        border-bottom-left-radius: 5%;
        border-bottom-right-radius: 5%;
        &::before,
        &::after {
          content: '';
          height: 1px;
          flex: 1;
          background-color: darkgrey;
          margin: 1em;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .ProjectMessenger {
    flex-direction: column-reverse;
    overflow-y: scroll;
    height: 750px;
    .Members {
      max-height: 400px;
      margin-top: 1em;
    }

    &-Messages-Container {
      height: 1000px;
    }
  }
}
