@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Zilla+Slab:400,700');

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #f4f7fc;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.serif {
  font-family: var(--font-family-serif);
}

.sans {
  font-family: var(--font-family-sans);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-serif);
}
