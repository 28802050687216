.ProjectMessengerInput {
  .input-group {
    background-color: var(--main-light-gray);
    border: none;
    border-radius: 10px;
    .inputZone {
      background-color: var(--main-light-gray);
      border: none;
      border-radius: 5px;
      color: #555;
      padding: 1.25em 5.5em 1.25em 1em;

      &::placeholder {
        color: #a9a9a9;
      }
    }

    .actions {
      z-index: 5;
      display: flex;
      position: absolute;
      right: 0;
      padding-right: 0.5em;
      height: 100%;
      align-items: center;
      justify-content: center;

      .attachDocumentButton {
        margin-right: 0.5em;
      }

      .sendButton {
        background-color: var(--main-blue);
        color: white;
        border: none;
        border-radius: 5px;
        padding-right: 1em;
        padding-left: 1em;
      }
    }
  }
}
