.arrowed-h {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: var(--font-family-serif);
  margin-bottom: 20px;
  svg {
    min-width: 30px;
    margin-right: 0.5em;
    transform: rotate(0.25turn);
  }
}
