.History {
  padding: 0 1em 0;
  .vertical-timeline {
    margin: 2em 0;
    padding: 0;

    &::before {
      top: 35px;
      height: calc(
        100% - 60px
      ); // attention, il faut retoucher a ca des qu'on touche aux padding/margin
    }

    .vertical-timeline-element {
      .vertical-timeline-element-icon {
        svg {
          margin-left: -13px;
        }
      }

      .vertical-timeline-element-content {
        border-top: 3px solid darkgrey;
        padding: 0.25em 1em;
      }

      .vertical-timeline-element-date {
        float: right;
        color: #444;
        font-style: italic;
      }

      &--document-required .vertical-timeline-element-content {
        border-top: 3px solid #1474aa;
      }
      &--document-validated .vertical-timeline-element-content {
        border-top: 3px solid lightgreen;
      }
      &--document-incomplete .vertical-timeline-element-content {
        border-top: 3px solid purple;
      }
      &--document-refused .vertical-timeline-element-content {
        border-top: 3px solid red;
      }
      &--meeting-cancelled .vertical-timeline-element-content {
        border-top: 3px solid red;
      }
      &--meeting-new .vertical-timeline-element-content {
        border-top: 3px solid green;
      }
      &--meeting-update .vertical-timeline-element-content {
        border-top: 3px solid #1474aa;
      }
      &--start .vertical-timeline-element-content {
        border-top: none;
      }
    }
  }

  @media only screen and (min-width: 1170px) {
    .vertical-timeline {
      margin: 2em 0;
      padding: 0;
    }
    .vertical-timeline-element {
      margin: 2em 0;
    }
  }
}
