.Resources {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .Categories {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    padding-bottom: 1em;

    &::after {
      content: '';
      width: 100%;
      position: absolute;
      height: 1px;
      background-color: lightgrey;
      bottom: 0;
    }

    .Category {
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid var(--main-blue);
      border-radius: 25px;
      color: var(--main-blue);
      position: relative;
      transition: all 100ms linear;

      &:not(:first-child) {
        margin-left: 20px;
      }

      &::after {
        transition: all 100ms linear;
        content: '';
        width: 100%;
        position: absolute;
        height: 0;
        background-color: var(--main-blue);
        bottom: -1.2em;
        left: 0;
        z-index: 5;
      }

      &:hover {
        color: white;
        background-color: var(--main-blue-lighter);
        border-color: var(--main-blue-lighter);
      }

      &.active {
        color: white;
        background-color: var(--main-blue);
        border-color: var(--main-blue);
      }
      margin-bottom: 5px;
    }

    &.oneLine {
      .Category {
        margin-bottom: 0;
        &.active {
          &::after {
            height: 5px;
          }
        }
      }
    }
  }

  .Themes {
    display: flex;
    flex-flow: row wrap;
    position: relative;

    .Theme {
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid var(--main-blue);
      border-radius: 25px;
      color: var(--main-blue);
      position: relative;
      transition: all 100ms linear;

      &:not(:first-child) {
        margin-left: 20px;
      }

      &:hover {
        color: white;
        background-color: var(--main-blue-lighter);
        border-color: var(--main-blue-lighter);
      }

      &.active {
        color: white;
        background-color: var(--main-blue);
        border-color: var(--main-blue);

        &::after {
          height: 5px;
        }
      }
    }
  }
}
