.DocumentUploader {
  height: auto;
  transition: height 1s ease;

  .NewFilesContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5em;

    .File {
      margin: 0 5px 5px;
      height: 100px;
      width: 90px;
      border: var(--main-blue) solid 2px;
      display: flex;
      font-size: x-small;
      align-items: center;
      border-radius: 5px;
      position: relative;
      padding: 5px;
      flex-wrap: wrap;
      user-select: none;

      span {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }

      .StatusIndicator {
        position: absolute;
        top: -5px;
        right: -5px;
        border-radius: 50%;
        background-color: var(--main-yellow);
        width: 20px;
        height: 20px;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .removeFileButton {
        position: absolute;
        bottom: 5px;
        right: 5px;
        cursor: pointer;
        transition: all 100ms linear;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        padding: 2px;

        &:hover {
          color: var(--main-red);
        }
      }
    }
  }

  &-SendButton {
    background-color: var(--main-blue);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 30px;
      width: 30px;
    }
  }

  .dropzone {
    &.dashb {
      width: 90px !important;
      color: var(--main-blue) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      &:active {
        background-color: transparent;
        border-color: var(--main-blue) !important;
      }
      &:focus {
        box-shadow: none;
      }
    }

    background-color: white;
    border: var(--main-blue) dashed 2px;
    padding: 10px;
    font-size: small;
    transition: height 1s ease;
    position: relative;
    cursor: pointer;

    width: 100%;
    height: 100px;
    margin: 0 5px 10px;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-size: large;
        text-align: center;
        user-select: none;
      }

      svg {
        color: darkgrey;
        width: 25px;
        height: 25px;
        margin-top: 10px;
        transition: all 250ms linear;
      }
    }

    &:focus {
      outline: 0;
    }
    &:hover {
      background-color: #eee;
    }
    .file-list {
      list-style: none;
    }
    &.isActive {
      background-color: lightgray;
    }

    &.disabled {
      cursor: not-allowed;
      color: lightgrey;
      border-color: var(--main-blue-lighter);
      svg {
        color: lightgrey;
        transform: rotate(0.625turn);
      }
    }
  }
  .size-limit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .size-limit-progress-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      position: relative;
      margin-right: 10px;
      .size-limit-progress {
        position: absolute;
        height: 5px;
        transition: width ease 200ms;
        background-color: darkgrey;
        z-index: 8000;
      }
    }
  }

  .uploading {
    min-height: 50px;
    position: relative;
    background-color: lightgray;
    border-radius: 5px;
    .uploading-progress {
      border-radius: 5px;
      position: absolute;
      height: 50px;
      transition: width ease 200ms;
      background-color: var(--main-blue);
    }
    .uploading-text {
      position: absolute;
      right: 20px;
      font-size: 20px;
      font-weight: bold;
      top: 10px;
    }
  }
}

.breakDocumentUploader {
  flex-basis: 100%;
}
