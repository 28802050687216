.ProjectDetails {
  .card-important {
    font-weight: bold;
    text-align: center;
  }

  .label {
    color: var(--main-green);
    font-weight: bold;
  }

  .doc-title-separator {
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5em;
    color: var(--main-green);
  }

  .add-document {
    color: var(--main-green);
    cursor: pointer;
  }
}
