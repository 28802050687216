.MessageBloc {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  min-width: 200px;
  &-Own {
    margin-left: auto;
    align-self: flex-end;
    flex-direction: row-reverse;
  }
  &-System {
    align-self: center;
    margin-left: auto;
    margin-right: auto;

    .Message {
      background-color: #282c34 !important;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .MessageAndTime {
      display: flex;
      flex-direction: column;
    }
  }

  .MessageAndTime {
    display: flex;
  }

  .messages {
    .senderName {
      margin-left: 5px;
      font-weight: bold;
    }

    .Message {
      background-color: lightgrey;
      border-color: #282c34;

      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      white-space: pre-wrap;

      word-break: break-word;

      color: white;
      padding: 10px 20px;
      margin: 0 5px 5px 5px;

      &-Own {
        border-top-left-radius: 20px;

        background-color: var(--main-blue);
        align-self: flex-end;
      }

      &-Other {
        border-top-right-radius: 20px;
        background-color: darkgrey;
      }

      &-Advisor {
        background-color: var(--main-light-gray);
        color: black;
      }

      &-Time {
        color: darkgrey;
      }
    }
  }
}
