.Documents {
  font-size: 14px;
  .NoDocument {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    flex-direction: column;
    padding-top: 1em;
    text-align: center;

    &-title {
      padding-top: 1em;
      font-weight: bold;
      font-size: larger;
    }
  }
}
