@import './bootstrap-override.scss';
@import './colors.scss';
@import './inputs.scss';
@import './fonts.scss';

.App {
  height: 100%;
  width: 100%;
}

.divider {
  height: 1px;
  background-color: lightgrey;
  width: 80%;
  margin: 1.5em 10%;
}

table,
th,
td {
  border: 1px solid black;
  text-align: left;
}

th,
td {
  padding: 1rem;
}

th {
  background-color: var(--main-blue);
}

a {
  color: var(--main-green);
}

a:hover {
  color: var(--main-green);
  text-decoration: underline;
}

.consent-container {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.consent-button {
  border: none;
  margin: 5px;
  font-family: var(--font-family-sans);
}

.consent-button-accept {
  background-color: var(--main-green);
}

.consent-button-decline {
  background-color: var(--main-yellow);
  &:hover {
    background-color: #b38321;
  }
}
