.header-counter-badge {
  background-color: var(--main-red);
  user-select: none;
}

.UserNotificationsPopover {
  padding: 0;

  .notifications {
    max-height: 300px;
    overflow-y: auto;
  }

  .empty-button {
    display: flex;
    width: 100%;
    height: auto;
    font-size: small;
    align-items: center;
    justify-content: space-between;
    .fa-layers {
      margin-right: 0.5em;
      margin-left: 0.5em;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  .no-notifications {
    text-align: center;
    padding: 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.nav {
  height: 110px;
  background-color: white;
  transition: height 200ms linear;
  align-content: center;
  .navbar-brand {
    img {
      top: 0;
      left: 0;
      height: auto;
      width: 200px;
      position: absolute;
    }
  }

  .NavbarContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-left: 200px;
    width: 100%;
    padding-right: 20px;

    .handle {
      margin: 0 auto 0;
      display: flex;
      height: 30px;
      font-style: italic;

      svg {
        align-self: flex-end;
        color: var(--main-green);
      }

      span {
        font-size: x-large;
        height: 30px;
        user-select: none;
      }
    }

    .burger {
      display: none;
    }

    #messengerMenu {
      margin-left: 3rem;
    }

    #userMenu {
      margin-left: 3rem;
    }

    #userMenuPopup {
      margin-left: 3rem;
      button {
        color: var(--main-green) !important;
      }
    }
  }
}

.navbar {
  padding: 3.8rem 1rem !important;
}

.Header-disconnect {
  padding: 0;
  font-size: small;
}

@media (max-width: 845px) {
  .nav {
    .NavbarContent {
      //margin-top: 3em;
      margin-left: auto;
      .handle {
        display: none;
      }
    }
    .navbar-toggler {
      margin-top: -20px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 576px) {
  .nav {
    .NavbarContent {
      margin-top: 4em;
      justify-content: center;
      padding-right: 0;

      .navbarItems {
        width: 100%;
        justify-content: space-around;
        svg {
          max-height: 35px;
        }
      }

      .burger {
        display: block;
      }

      #messengerMenu {
        margin-left: 0;
      }

      #userMenu {
        margin-left: 0;
        right: 20px;
        top: 10px;
      }

      #userMenuPopup {
        margin-left: 0;
      }
    }
  }

  .nav.expanded {
    height: 120px;
  }

  .nav .navbar-brand img {
    width: 120px;
  }
}

.down {
  color: red;
  cursor: default;
  * {
    color: grey;
  }
}
