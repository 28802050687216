.Deliverable {
  justify-content: center;

  &-Content {
    > .card {
      margin-bottom: 2em;
      > span {
      }
    }
    p {
      white-space: pre-wrap;
    }
  }

  &-Button {
    background-color: var(--main-green);
    color: var(--main-white);
    border-radius: 5px;
    border-style: none;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &-Download {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    color: var(--main-gray-4);
    background-color: var(--main-white);
    padding: 2em;
    > * {
      margin: 0.5em 0;
    }
    margin-bottom: 2em;
    &--PDF {
      background-color: white;
      padding: 0.3em 1em;
      border-radius: 25px;
      color: var(--main-green);
    }
  }

  .pact {
    color: #007bff;
    &:hover {
      color: var(--main-black-1);
    }
  }
  .MementoIsDown {
    background-color: lightgrey;

    .pdfButton {
      background-color: white;
      padding: 0.3em 1em;
      border-radius: 25px;
      color: var(--main-black-1);
    }
  }

  .Memento {
    color: var(--main-gray-4);
    font-size: 18px;
    h1 {
      font-weight: bold;
    }
  }

  .MementoPdf {
    background-color: var(--main-green-1);
  }

  .Image-Feature-1 {
    width: 75%;
    margin-left: 55px;
  }

  .Image-Feature-2 {
    width: 75%;
    margin: -85px;
  }

  .TitreFeature {
    color: var(--main-black-1);
    margin-top: 80px;
    font-weight: bold;
  }

  .textFeature {
    text-align: justify;
    font-size: large;
    color: var(--main-black-1);
    margin-top: 25px;
  }

  .IconMementoPdf {
    width: 100%;
    margin: -115px;
  }

  .IconDownloadPdf {
    width: 80px;
    height: 80px;
    padding: 15px;
    color: var(--main-white);
    border-radius: 50%;
    background-color: var(--main-green-3);
  }

  .TextDownloadPdf {
    color: var(--main-white);
    font-weight: bold;
    font-size: 30px;
    text-align: center;
  }
}
