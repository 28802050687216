.Profile {
  &-Header {
    margin-bottom: 2em;
  }

  .info-container {
    padding-top: 1em;

    .divider {
      margin: 1em 0 1em;
      width: 100%;
    }

    .info-group {
      display: flex;
      flex-direction: column;
      padding: 0 2em 0;

      .info-label {
        color: var(--main-green);
        font-weight: bold;
        font-size: small;
      }

      .info-value {
        font-weight: bold;
      }
    }
  }
}
