.LeftMenu {
  width: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: fit-content;
  transition: all ease 200ms;
  position: sticky;
  top: 1rem;
}

@media (max-width: 576px) {
  .LeftMenu {
    //width: 60px;
    top: auto;
    position: absolute;
    z-index: 100;
    box-shadow: 4px 20px 8px 0 rgba(0, 0, 0, 0.2), 0 22px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .MenuCanvas {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(120, 120, 120, 0.5);
    z-index: 50;
    top: auto;
    margin-top: -25px;
  }
}
