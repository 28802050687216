.Home {
  min-height: 100%;
  &-Content {
    width: calc(100vw - 220px);
    margin: 0 34px 24px;
    transition: width 100ms linear;
  }
}

@media (max-width: 768px) {
  .Home {
    &-Content {
      width: calc(100vw - 50px);
      margin: 0 15px 24px;
    }
  }
}

@media (max-width: 576px) {
  .Home {
    &-Content {
      width: 100%;
      margin: 0 10px 24px;
    }
  }
}
