.Meetings {
  min-width: 340px;
  font-size: 14px;
  flex: auto;
  margin-left: 0 !important;
  display: flex;
  height: 100%;
  .divider {
    height: 0;
    overflow: hidden;
    border-top: 2px solid #e9ecef;
    margin-bottom: 20px;
  }
  &-list:last-child {
    .divider {
      border-top: none;
      margin: 0 !important;
    }
  }

  .NoMeeting {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    flex-direction: column;
    text-align: center;

    svg {
      color: var(--main-green);
    }

    &-title {
      padding-top: 1em;
      font-weight: bold;
      font-size: larger;
    }
  }
}
