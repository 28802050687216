.PersonalInfo {
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div {
    margin-bottom: 2em;
  }

  .card {
    height: 100%;
  }
}
