.MenuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 122px;
  padding: 20px;
  color: var(--item-color);
  position: relative;

  transition: background-color 200ms linear, min-width 200ms ease;

  &::after {
    content: '';
    background-color: var(--item-color);
    transition: all 200ms linear;
    position: absolute;
    float: right;
    height: 100%;
    right: 0;
    opacity: 0;
    width: 5px;
  }

  &:hover {
    &::after {
      opacity: 0.5;
    }
  }

  &.active {
    &::after {
      opacity: 1;
    }
  }

  &-divider {
    height: 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  &-label {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
  }

  &--Toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--item-color);
    color: white;
    border: solid 1px var(--item-color);
    margin-left: 10px;
    transition: all 500ms ease;
    transform: rotate(90deg);

    &:hover {
      background-color: var(--item-color);
      color: white;
    }

    &:focus {
      outline: 0;
    }

    &.toggled {
      background-color: white;
      color: var(--item-color);
      transform: rotate(-90deg);
    }
  }

  &-SubItems {
    display: flex;
    flex-direction: column;

    &-SubItem {
      font-weight: bold;
      color: white;
      margin-bottom: 1px;
      padding: 15px;
      position: relative;

      &-Project-color-blue {
        &::after {
          background-color: var(--main-blue);
        }
      }

      &-Project-color-yellow {
        &::after {
          background-color: var(--main-yellow);
        }
      }

      &-Project-color-green {
        &::after {
          background-color: var(--main-green);
        }
      }

      &::after {
        content: '';
        position: absolute;
        float: right;
        height: 100%;
        width: 5px;
        right: 0;
        top: 0;
        transition: all 100ms linear;
      }

      &:hover {
        color: white;
        &::after {
          opacity: 0.2;
        }
      }

      &.active {
        &::after {
          opacity: 0.4;
        }
        text-decoration: underline;
      }
    }
  }
}

//@media (max-width: 576px) {
//  .MenuItem {
//    min-height: 75px;
//
//    &-label {
//      display: none;
//    }
//    &-SubItems {
//      &-SubItem {
//        font-size: 0;
//        &::first-letter {
//          font-size: small;
//          text-transform: capitalize;
//        }
//      }
//    }
//  }
//}
